import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../shared/fonts';
import { space, border } from '../../shared/spacing';
import { mediaQueries } from '../../shared/breakpoints';
import colors from '../../shared/colors';
import LensIcon from '../../icons/Lens';
import ClearIcon from '../../icons/Clear';
import { pixelsToRem } from '../../utils/units';
import { noop } from '../../utils/fn';

const generateInputStyle = hasError => css`
  appearance: none;
  border: ${border.size} solid ${hasError ? colors.error : colors.greyLight};
  border-radius: ${border.radius};
  padding: ${space.x15} ${pixelsToRem(36)} ${space.x15} ${pixelsToRem(36)};
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: ${colors.greyLight};
  }

  &::placeholder {
    font-size: ${fonts.sizes.s};
    color: ${colors.greySeparator};
  }

  ${mediaQueries.md(css`
    padding: ${space.x125} ${pixelsToRem(36)} ${space.x125} ${pixelsToRem(36)};
  `)};
`;

const Input = styled.input`
  ${props => generateInputStyle(props.hasError)};
`;

const InputContainer = styled.div`
  position: relative;
`;

const StyledLens = styled(LensIcon)`
  color: ${colors.grey};
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 10px;

  ${mediaQueries.md(css`
    top: 11px;
  `)};
`;

const StyledClearSearch = styled(ClearIcon)`
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;

  ${mediaQueries.md(css`
    top: 8px;
  `)};
`;

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.ref = createRef();
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.ref.current.focus();
    }
  }

  render() {
    const {
      hasError = false,
      onClearSearchClicked = noop,
      className = null,
      placeholder = '',
      disabled = false,
      autoFocus = false,
      ...rest
    } = this.props;
    const value = rest.value || '';

    return (
      <InputContainer className={className}>
        <Input
          ref={this.ref}
          hasError={hasError}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          {...rest}
        />
        <StyledLens />
        {value && value.trim() && (
          <StyledClearSearch onClick={() => onClearSearchClicked()} />
        )}
      </InputContainer>
    );
  }
}

/* eslint-disable react/require-default-props */
SearchInput.propTypes = {
  /** Class name to extend the styles */
  className: PropTypes.string,
  /** Placeholder that the input will have */
  placeholder: PropTypes.string,
  /** Indicates whether the input has an error */
  hasError: PropTypes.bool,
  /** Disables the input  */
  disabled: PropTypes.bool,
  /** Fires an event when the "clear search" button is clicked */
  onClearSearchClicked: PropTypes.func,
  /** Focus automatically on mount */
  autoFocus: PropTypes.bool
};

export default SearchInput;
