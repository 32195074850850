import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../utils/fn';

import S from './styles';

const RichCard = forwardRef(
  (
    { showCloseButton = false, background = null, onClose = noop, children },
    ref
  ) => (
    <S.RichCardWrapper ref={ref}>
      <S.RichCardContentContainer background={background}>
        {showCloseButton && <S.CloseIcon onClick={onClose} />}
        {children}
      </S.RichCardContentContainer>
    </S.RichCardWrapper>
  )
);

RichCard.Button = S.Button;
RichCard.Row = S.Row;
RichCard.CellSpace = S.CellSpace;

RichCard.propTypes = {
  // eslint-disable-next-line react/require-default-props
  showCloseButton: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default RichCard;
