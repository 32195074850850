/* eslint-disable react/require-default-props */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';
import RichCard from '../../../RichCard/RichCard';

import { noop } from '../../../../utils/fn';

import {
  positions,
  alignments,
  positionsArray,
  alignmentsArray
} from '../../tooltip-positions';

import colors from '../../../../shared/colors';

import * as S from './styles';

const RichTooltip = forwardRef(
  (
    {
      children,
      background = colors.darkBlue,
      color = colors.white,
      content,
      hasButton = false,
      buttonText = '',
      onButtonClick = noop,
      isOpen = undefined,
      position = positions.DOWN,
      alignment = alignments.MIDDLE,
      ...rest
    },
    ref
  ) => {
    return (
      <S.StyledTooltip
        position={position}
        alignment={alignment}
        {...rest}
        background={background}
        color={color}
        content={
          <RichCard ref={ref} background={background}>
            {content}
            {hasButton && (
              <RichCard.Row topMargin>
                <S.StyledButton onClick={onButtonClick}>
                  {buttonText}
                </S.StyledButton>
              </RichCard.Row>
            )}
          </RichCard>
        }
        tipContentHover
        isOpen={isOpen}
      >
        {children}
      </S.StyledTooltip>
    );
  }
);

RichTooltip.propTypes = {
  /** Css background property */
  background: PropTypes.string,
  /** Css color property */
  color: PropTypes.string,
  /** Position of the tooltip relative to the target (children) */
  position: PropTypes.oneOf(positionsArray),
  /** The alignment of the content */
  alignment: PropTypes.oneOf(alignmentsArray),
  /** Content of the tooltip */
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Whether a button to close/interact with the tooltip should be displayed */
  hasButton: PropTypes.bool,
  /** The text to display on the button */
  buttonText: PropTypes.string,
  /** The function to execute when the button is clicked */
  onButtonClick: PropTypes.func,
  /** Whether the tooltip should be displayed or not. Null allows activation by hover */
  isOpen: PropTypes.oneOf([true, false, null]),
  /** The target */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

RichTooltip.positions = Tooltip.positions;
RichTooltip.alignments = Tooltip.alignments;
RichTooltip.positionsArray = Tooltip.positionsArray;
RichTooltip.alignmentsArray = Tooltip.alignmentsArray;

export default RichTooltip;
