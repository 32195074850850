import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import { NotificationIconButton } from './styles';

const NotificationIcon = forwardRef(({ isActive = false, onClick }, ref) => {
  return (
    <NotificationIconButton
      isActive={isActive}
      data-testid="notification-button"
      onClick={onClick}
      ref={ref}
      size="large"
    >
      <NotificationsOutlinedIcon fontSize="large" />
    </NotificationIconButton>
  );
});

NotificationIcon.propTypes = {
  // eslint-disable-next-line react/require-default-props
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default NotificationIcon;
